import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Field, Formik } from "formik";
import { TextField } from "@components/FormikFields";
import { Modal } from "@components/Modal";
import { Editor } from "@monaco-editor/react";
import { monacoEditorOptions } from "@common/constant/monacoOption";
import { NameContainer } from "@components/FormikFields/components";
import { IDeleteObjectRequest, IObjectRequest, IObjectsRequest } from "@screens/Objects/Objects.types";
import { ObjectEnum, PermissionEnum } from "@screens/Objects/Object/Object.constants";
import { deleteObjectRequest, editAndCreateObjectRequest, getObjectByKindRequest } from "@services/Objects";
import base64ToJson from "@common/methods/base64toJSON";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import RootState from "@state/interfaces";
import * as localStyle from "./Override.styled.scss";
import { checkObjectResponse } from "@common/methods/objectRequest";
import { IErrorResponse } from "@interfaces";
import { errorInfo } from "@state/error";
import { ClientConfigOverride, initialOverride, OverrideObjectType } from "@screens/Overrides/Overrides.types";
import { initObject } from "@screens/Objects/Objects.utils";
import * as commonStyle from "@common/style/style.scss";
import * as buttonStyle from "@components/Button/style.scss";
import filterEmptyStrings from "@common/methods/filterEmptyStringValues";
import jsonToBase64 from "@common/methods/jsonToBase64";
import { GLOBAL_PAGE, NAMESPACES_PAGE, NEW_PAGE } from "@common/constant/urls";
import iconSetting from "@common/img/svg/setting-icon.svg";
import { EnvIconContainer } from "@common/icons/EnvIconContainer";
import iconDelete from "@common/img/svg/delete-icon.svg";

function Override(): ReactElement {
    const navigate = useNavigate();
    const { override = "", project = "" } = useParams();
    const account = useSelector((state: RootState) => state.account);
    const [overrideObject, setOverrideObject] = useState<OverrideObjectType>({ ...initObject, data: initialOverride });
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const objectPermissions = useSelector((state: RootState) => state.objectPermissions);
    const canCreateAndEdit = objectPermissions[ObjectEnum.CLIENT_CONFIG_OVERRIDE]?.includes(PermissionEnum.PUT);

    const getData = useCallback(async (): Promise<void> => {
        const dataPoolConfig: IObjectsRequest = {
            kind: ObjectEnum.CLIENT_CONFIG_OVERRIDE,
            name: override,
            metadata_only: false,
            namespace: project !== GLOBAL_PAGE ? project : "",
        };

        const { data: dataConfig } = await getObjectByKindRequest(dataPoolConfig);

        if (!dataConfig || !dataConfig.object) {
            return;
        }
        const objectConfig = base64ToJson(dataConfig.object.data) as ClientConfigOverride;
        setOverrideObject({ metadata: dataConfig.object.metadata, data: objectConfig });
    }, [override]);

    const deleteObject = async () => {
        try {
            setIsDeleteModal(false);
            const {
                metadata: { kind, name, revision },
            } = overrideObject;

            const object: IDeleteObjectRequest = {
                kind,
                name,
                requireRevision: revision,
                returnPrevious: false,
                namespace: project !== GLOBAL_PAGE ? project : "",
            };
            const { data } = await deleteObjectRequest(object);
            checkObjectResponse(data, () => navigate(`/overrides/${project}`));
        } catch (e: unknown | IErrorResponse) {
            const error = e as IErrorResponse;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    useEffect(() => {
        if (!account.authorized) return;
        getData().then();
    }, [account.authorized, getData]);

    return (
        <div className={commonStyle.mainPageContainer}>
            <div className={localStyle.overrideForm}>
                <Modal
                    isNegative
                    isOpen={isDeleteModal}
                    onClose={() => setIsDeleteModal(false)}
                    title={window.locales.deleteOverride}
                    confirm={{
                        label: window.locales.delete,
                        onConfirm: deleteObject,
                    }}
                >
                    Do you want to delete the override {overrideObject.metadata.name}?
                </Modal>
                <Formik
                    initialValues={{
                        name: overrideObject.metadata.name,
                        user: overrideObject.data.user,
                        device: overrideObject.data.device,
                        ip_selector: JSON.stringify(overrideObject.data.ip_selector ?? {}, undefined, 4),
                        server_selector: JSON.stringify(overrideObject.data.server_selector ?? {}, undefined, 4),
                    }}
                    enableReinitialize={true}
                    validate={({ name, user, device }) => {
                        const errors: Record<string, string> = {};
                        if (!name) errors.name = window.locales.emptyField;
                        if (!user || !device) {
                            errors.user = window.locales.emptyField;
                            errors.device = window.locales.emptyField;
                        }

                        return errors;
                    }}
                    onSubmit={async ({ name, user, device, ip_selector, server_selector }) => {
                        try {
                            const dataObject = {
                                user,
                                device,
                                ip_selector: JSON.parse(ip_selector),
                                server_selector: JSON.parse(server_selector),
                            };

                            const object = filterEmptyStrings({
                                ...overrideObject.metadata,
                                name,
                                requireRevision: overrideObject.metadata.revision,
                                data: jsonToBase64(dataObject),
                                kind: ObjectEnum.CLIENT_CONFIG_OVERRIDE,
                                namespace: project !== GLOBAL_PAGE ? project : "",
                                onlyCreate: false,
                                onlyUpdate: false,
                                returnPrevious: false,
                                returnCurrent: true,
                            }) as IObjectRequest;
                            const { data } = await editAndCreateObjectRequest({
                                ...object,
                                onlyCreate: override === NEW_PAGE,
                                onlyUpdate: override !== NEW_PAGE,
                            });

                            if (!data) return;

                            checkObjectResponse(data, () => {
                                if (override === NEW_PAGE) {
                                    navigate(`/overrides/${project}`);
                                } else {
                                    const object = base64ToJson(data.current.data) as ClientConfigOverride;
                                    setOverrideObject({ metadata: data.current.metadata, data: object });
                                }
                            });
                        } catch (e: unknown | IErrorResponse) {
                            const error = e as IErrorResponse;
                            errorInfo.setErrorInfo({
                                title: error.code,
                                description: error.message,
                            });
                        }
                    }}
                >
                    {({ values, handleSubmit, setFieldValue }) => (
                        /* ! add id to match nameForm from the Modal props  */
                        <form id="tokenForm" onSubmit={handleSubmit} className={localStyle.overridePage}>
                            <section>
                                <Field
                                    component={TextField}
                                    placeholder="Type name"
                                    type="text"
                                    name="name"
                                    disabled={overrideObject.metadata.name}
                                />
                            </section>
                            <section>
                                <Field component={TextField} placeholder="Type user" type="text" name="user" />
                            </section>
                            <section>
                                <Field component={TextField} placeholder="Type device" type="text" name="device" />
                            </section>
                            {/* IP Selector */}
                            <section>
                                <NameContainer $isError={false}>{window.locales.ip_selector}</NameContainer>
                                <Editor
                                    height="30vh"
                                    options={monacoEditorOptions}
                                    value={values.ip_selector}
                                    onChange={(value) => setFieldValue("ip_selector", value)}
                                    theme="vs-dark"
                                    defaultLanguage="json"
                                />
                            </section>

                            {/* Server Selector */}
                            <section>
                                <NameContainer $isError={false}>{window.locales.server_selector}</NameContainer>
                                <Editor
                                    height="30vh"
                                    options={monacoEditorOptions}
                                    value={values.server_selector}
                                    onChange={(value) => setFieldValue("server_selector", value)}
                                    theme="vs-dark"
                                    defaultLanguage="json"
                                />
                            </section>
                            <div className={localStyle.buttons}>
                                <div className={buttonStyle.groupButtonContainer}>
                                    <button
                                        type="submit"
                                        disabled={!canCreateAndEdit}
                                        className={buttonStyle.buttonSubmit}
                                    >
                                        {window.locales.save}
                                    </button>
                                    <button
                                        className={buttonStyle.buttonCancel}
                                        onClick={() => navigate(`/overrides/${project}`)}
                                    >
                                        {window.locales.cancel}
                                    </button>
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
                <div className={localStyle.headerBtn}>
                    <EnvIconContainer
                        action={() =>
                            navigate(
                                `/${NAMESPACES_PAGE}/${GLOBAL_PAGE}/${ObjectEnum.CLIENT_CONFIG_OVERRIDE}/${override}`,
                            )
                        }
                    >
                        <img src={iconSetting} alt="img" />
                    </EnvIconContainer>
                    <EnvIconContainer action={() => setIsDeleteModal(true)}>
                        <img src={iconDelete} alt="img" />
                    </EnvIconContainer>
                </div>
            </div>
        </div>
    );
}

export default Override;
