import React, { useEffect, useMemo, useRef, useState } from "react";
import { Formik } from "formik";
import { useDispatch } from "react-redux";

import { InputCode } from "@screens/Landing/Authorization/InputCode";
import { resetActiveProject } from "@state/activeProject";
import * as authorizationStyle from "@screens/Landing/Authorization/style.scss";
import { Timer } from "@components/Timer";
import { errorInfo } from "@state/error";
import { IErrorResponse } from "@interfaces";

import * as style from "./style.scss";

const CodeForm = ({ resolver }: { resolver: any }) => {
    const dispatch = useDispatch();
    const captchaRef = useRef(null);
    const verificationId = useRef("");
    const [isError, setIsError] = useState(false);
    const [multiFactorHint = { phoneNumber: "" }] = useMemo(() => resolver?.hints ?? [], [resolver?.hints]);
    const { phoneNumber } = multiFactorHint;
    const recaptchaVerifier = useRef(null);
    const [isResend, setIsResend] = useState(false);

    const verifyCode = async (code: string) => {
        try {
            const cred = window.fb.default.auth.PhoneAuthProvider.credential(verificationId.current, code);
            const multiFactorAssertion = window.fb.default.auth.PhoneMultiFactorGenerator.assertion(cred);
            await resolver.resolveSignIn(multiFactorAssertion);
            dispatch(resetActiveProject());
        } catch (e: any) {
            setIsError(true);
            const error: IErrorResponse = e;
            errorInfo.setErrorInfo({
                title: error.code,
                description: error.message,
            });
        }
    };

    const resendCode = () => {
        setIsResend(false);
        const phoneInfoOptions = {
            multiFactorHint,
            session: resolver?.session,
        };

        const phoneAuthProvider = new window.fb.default.auth.PhoneAuthProvider();
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier.current).then((value: string) => {
            verificationId.current = value;
        });
    };

    useEffect(() => {
        if (!resolver) {
            return;
        }

        recaptchaVerifier.current = new window.fb.default.auth.RecaptchaVerifier(captchaRef.current, {
            size: "invisible",
        });
        const phoneInfoOptions = {
            multiFactorHint,
            session: resolver?.session,
        };

        const phoneAuthProvider = new window.fb.default.auth.PhoneAuthProvider();
        phoneAuthProvider
            .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier.current)
            .then((value: string) => {
                verificationId.current = value;
            })
            .catch(() => {
                // @ts-ignore
                window.grecaptcha.reset(captchaRef.current);
            });
    }, [multiFactorHint, resolver]);

    return (
        <div className={authorizationStyle.formCenter}>
            <div className={authorizationStyle.signFormContainer}>
                <div className={authorizationStyle.titleContainer}>
                    <div className={authorizationStyle.title}>Verification</div>
                    <div className={authorizationStyle.text}>
                        Enter 6-digit code, we’ve sent you on <span>{phoneNumber}</span>
                    </div>
                </div>
                <Formik initialValues={{ code: "" }} onSubmit={() => {}}>
                    {({ handleSubmit }) => (
                        <form onSubmit={handleSubmit} className={authorizationStyle.formContainer}>
                            <div className={authorizationStyle.fieldContainer}>
                                <InputCode
                                    isError={isError}
                                    submit={async (code: string) => {
                                        setIsError(false);
                                        await verifyCode(code);
                                    }}
                                />
                            </div>
                            <div className={authorizationStyle.infoContainer}>
                                <span>Can’t find the code?</span>
                                <div className={style.buttonResend}>
                                    {isResend ? (
                                        <button type="button" onClick={resendCode}>
                                            Resend
                                        </button>
                                    ) : (
                                        <Timer
                                            takeOffTimer={() => {
                                                setIsResend(true);
                                                setIsError(false);
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        </form>
                    )}
                </Formik>
                <div ref={captchaRef} />
            </div>
        </div>
    );
};

export { CodeForm };
