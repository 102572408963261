import React, { useCallback, useState } from "react";
import { getAuth, getMultiFactorResolver } from "firebase/auth";

import SignInWithOkta from "./WithOkta";
import SignInWithPassword from "./WithPassword";
import { MultiFactorResolver } from "@firebase/auth";

const SignInForm = ({
    goToForgotPwd,
    goToMFA,
}: {
    goToForgotPwd: () => void;
    goToMFA: (resolver: MultiFactorResolver) => void;
}) => {
    const [isPasswordForm, setIsPasswordForm] = useState(false);
    const [email, setEmail] = useState("");

    const changeSignInMethod = useCallback((value: string) => {
        setEmail(value);
        setIsPasswordForm(true);
    }, []);

    const isMfaError = useCallback(
        (error: any) => {
            const auth = getAuth();
            if (error && error.code === "auth/multi-factor-auth-required") {
                const resolver = getMultiFactorResolver(auth, error);
                goToMFA(resolver);
                return true;
            }
            return false;
        },
        [goToMFA],
    );

    return isPasswordForm ? (
        <SignInWithPassword email={email} isMfaError={isMfaError} goToForgotPwd={goToForgotPwd} />
    ) : (
        <SignInWithOkta goToForgotPwd={goToForgotPwd} email={email} changeForm={changeSignInMethod} />
    );
};

export default SignInForm;
