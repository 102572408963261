export default {
    country: "Country",
    objects: "Objects",
    namespaces: "Namespaces",
    session: "Session",
    namespace: "Namespace",
    projects: "Projects",
    project: "Project",
    pool: "Pool",
    passwordChanged: "Your password has been successfully changed",
    update: "Update",
    kind: "Kind",
    createTime: "Create time",
    age: "Age",
    author: "Author",
    updateTime: "Update time",
    code: "code",
    bridgePool: "Bridge pool",
    serverPool: "Server pool",
    device_id: "Device id",
    selectors: "Selectors",
    selector: "Selector",
    token: "Token",
    addKey: "Add key",
    editKey: "Edit key",
    metadata: "Metadata",
    data: "Data",
    expire_time: "Expire time",
    create_time: "Create time",
    update_time: "Update time",
    incorrectLicenseDate: "Date cannot be less than current date",
    sendCode: "send code",
    resend: "Resend",
    accountSecurity: "Account security",
    changePassword: "Change password",
    edit: "Edit",
    profileInformation: "Profile information",
    main: "Main",
    twoFactory: "Two-factor authentication",
    sentCode: "We have send six-digit verification  code to your phone number",
    invalidPwd: "Password is invalid",
    firstName: "First name",
    form: "Form",
    ui: "UI",
    accessDenied: "Access denied",
    saveDenied: "You have no right to save the object",
    readDenied: "You have no right to read the object",
    errorPutResultNotFound: "",
    errorPutResultAlreadyExists: "This object already exists, please create another one.",
    errorGetResultNotFound: "This object don't exist yet, please create one.",
    errorPutResultConflict: "The object version has been modified previously, update the object.",
    json: "JSON",
    continue: "Continue",
    lastName: "Last name",
    company: "Company name",
    city: "City",
    billing: "Billing",
    billingDetails: "Billing details",
    checkout: "Checkout",
    repay: "Repay",
    line1: "Line 1",
    line2: "Line 2",
    postalCode: "Postal Code",
    state: "State",
    phone: "Phone",
    companyCode: "Company code",
    choosePassword: "Choose password",
    repeatPassword: "Repeat password",
    whichProduct: "Which product do you want to use first",
    numberOfUsers: "Number of daily users of your app",
    tos: "Terms of Service",
    legal: "Legal",
    bullingPerUsers: "Bulling per users",
    limitedExceeded: "Limit is exceeded",
    limitedTraffic: "for users with 50Mb/day traffic",
    unlimitedTraffic: "for users with unlimited data",
    dependsCharge: "Your charge will depends on users traffic which they paid for ",

    invalidFirstName: "First name can not be empty",
    invalidEmptyPassword: "Password can not be empty",
    invalidEmptyCurrentPassword: "Enter the current password",
    invalidEmptyPhoneNumber: "Phone number can not be empty",
    invalidPhoneNumber: "Invalid format for phone number",
    emptyPrivateKey: "Private key can not be empty",
    incorrectPrivateKey: "Private key is not correct",
    invalidPrivateKey: "Private key should be less 6 letters long",
    incorrectPhoneNumber: "Phone number is invalid",
    invalidToken: "Login token has expired please try again",
    invalidEnroll: "Phone number already enrolled as second factor for this account",
    invalidCompanyName: "Company name can not be empty",
    invalidAuthMethod: "Auth method can not be empty",
    emptyCode: "Code can not be empty",
    invalidCode: "Code should be at least 6 numbers",
    incorrectCode: "Invalid verification code",
    incorrectPassword: "Invalid password",
    incorrectCompanyName: "Incorrect company name",
    incorrectProjectPrefix: "Project prefix must be exactly 8 letters long",
    incorrectLicenseToken: "Incorrect token",
    invalidAuthorizationType: "authorization type can not be empty",
    invalidLastName: "Last name can not be empty",
    emptyDomains: "Domains can not be empty",
    emptyDescription: "Description can not be empty",
    invalidDescription: "Please enter correct description",
    invalidVersion: "This field accepts Latin letters, numbers, dots or hyphens",
    emptyVersion: "This field can not be empty",
    invalidAgreement: "You must agree to the user agreement",
    invalidPasswordMatch: "Passwords are not equals",
    oldPassword: "The new password cannot be the same as the current password",
    weakPassword: "Password must contain at least 8 symbols including numbers and special characters.",
    invalidPassword: "Password should be at least 8 characters",
    reLogin: "Log in again before retrying this request.",
    invalidEmailMatchPassword: "Using your email as a password is a bad security practice.",
    invalidEmptyEmail: "Email can not be empty",
    invalidEmail: "Please enter correct email",
    invalidFieldEmpty: "Field can not be empty",
    invalidProjectId: "Please enter correct project id",
    invalidPrefixId: "Please add the prefix ID to your profile",
    invalidZip: "Please enter correct zip",
    invalidCity: "City can not be empty",
    invalidCountry: "Country can not be empty",
    invalidPostalCode: "Country can not be empty",
    invalidState: "State can not be empty",
    invalidProjectPrefix: "Project prefix can not be empty",
    invalidSMSCode: "SMS code can not be empty",
    invalidConfig: "Parsing error",
    incorrectSMSCode: "SMS code is invalid",
    incorrectValue: "Value is not correct",
    labelExists: "Label already exists",
    invalidPhone: "Phone can not be empty",
    invalidLine1: "Line 1 can not be empty",
    invalidInvite: "This user already active",
    invalidRole: "Need to select role",
    invalidProxy: "Need to select proxy",
    invalidService: "Service cannot be empty",
    emptyField: "Field cannot be empty",
    emptyName: "Name cannot be empty",
    invalidType: "Type cannot be empty",
    invalidAction: "Action cannot be empty",
    resendInvite: "Resend invite",
    deletePoolGroup: "Delete pool group",
    cancelPoolGroup: "Cancel user",
    deleteLocationRules: "Delete location rules",
    deleteLocation: "Delete location",
    deleteObject: "Delete object",
    deleteFeature: "Delete feature",
    deleteOverride: "Delete override",
    deleteEntitlement: "Delete entitlement",
    revokeGrant: "Revoke grant",
    revoke: "Revoke",
    updateObject: "Update object",
    locationRules: "Location rules",
    addLocationRules: "Add location rules",
    editLocationRules: "Edit location rules",

    signUpProducts: [
        "I am just exploring",
        "Proxy - protection for your app only",
        "VPN - protection for the whole OS",
        "Other",
    ],
    signUpUsers: [
        "Less than a 100,000",
        "More than a 100,000",
        "More than 500,000",
        "More than a 1,000,000",
        "Not a Production App",
    ],
    verify1: "A confirmation letter was sent to the specified mailbox.",
    verify2: "Please confirm your account in order to start using the application",
    haveAccount: "I have an account",
    backToSignUp: "Back to Sign Up",
    mainPage: "Main page",
    resetLink: "Reset link sended to your email.",
    dontHaveAccount: "I don`t have an account",
    addNetwork: "Add network",
    countries: "Countries",
    location: "Location",
    group: "Group",
    groups: "Groups",
    request_selector: "Request selector",
    server_selector: "Server selector",
    ip_selector: "IP selector",
    servers: "Servers",
    noServers: "No servers",
    connect_ip: "Connect IP",
    server_group: "Server group",
    send: "Send",
    label: "Label",
    remarkContactUs: "By clicking Send, you agree to our",
    forgotPassword: "Forgot password",
    descriptionForgotPassword:
        "Enter your Email or Username address and we will send you a link to reset your password",
    protocols: "Protocols",
    public: "Public",
    confirm: "Confirm",
    addRule: "Add rule",
    addFeature: "Add feature",
    addEntitlement: "Add entitlement",
    addGrant: "Add grant",
    grant: "Grant",
    editGrant: "Edit grant",
    editEntitlement: "Edit entitlement",
    editFeature: "Edit feature",
    editOverride: "Edit override",
    addOverride: "Add override",
    editRule: "Edit rule",
    oneDay: "1 day",
    sevenDays: "7 days",
    fourteenDays: "14 days",
    agreeWith: "I agree with",
    iAgree: "I agree",
    thirtyDays: "30 days",
    admin: "Admin",
    registeredUsers: "Registered users",
    registeredDevices: "Registered devices",
    default: "Default",
    light: "Light",
    white: "White",
    blue: "Blue",
    list: "List",
    card: "Card",
    english: "English",
    japanese: "Japanese",
    close: "Close",
    daily: "Daily",
    weekly: "Weekly",
    monthly: "Monthly",
    documentation: "documentation",
    menuProducts: "menu & products",
    all: "All",
    quickstart: "Quickstart",
    api: "API Reference",
    sdk: "SDKs",
    iOs: "IOS",
    macOs: "macOS",
    android: "Android",
    windows: "Windows",
    chromeExtension: "Chrome Extension",
    routers: "Routers",
    informationPricing: "information about pricing",
    titleFeature1: "Focus on Accessibility",
    textFeature1: "Choose from thousands of server endpoints around the world or the quickest for your users",
    titleFeature2: "We take privacy seriously",
    textFeature2: "We do not store, log or monitor individual user data or traffic",
    titleFeature3: "Platforms",
    textFeature3: "We support major client platforms:",
    titleFeature4: "Simple to manage",
    textFeature4: "Monitor and manage your apps from a single dashboard in your account",
    titleFeature5: "Works out of the “box”",
    textFeature5: "Easy to use in new applications as well as existing apps",
    titleFeature6: "Payment",
    textFeature6: "You only pay for what you use. Here is the ",
    search: "Search",
    product: "Product",
    platform: "Platform",
    protocol: "Protocol",
    build: "build",
    main1: "Freedom as a Service",
    main2: 'Build secure apps that can"t be',
    main3: "throttled or restricted",
    main4: "Get a FREE API key",
    main5: "Learn more",
    main6: "Keep Your Users Safe",
    main7: "VPN SDK",
    main8: "Enable a VPN for securing the entire operating system of your users. Protects your user data from malicious websites, ISPs, and hackers. Protects from unsafe networks (like static Wi-Fi). Puts you in control of the enter OS.",
    main9: "Make Your App Unstoppable",
    main10: "Proxy SDK",
    main11: "Protects your app from throttling, censorship, or any kind of interference from Internet Service providers. Protects your user data from ISPs and hackers. Ensures that your app is always available from anywhere in the world.",
    main12: "Secure Your Business Connection",
    main13: "VPN for business",
    main14: "Use AnchorFree’s proprietary, lightning-fast Hydra VPN and the AnchorFree Server ComponentNetwork (among the 100 largest networks in the world). Contact our partnership team for more information.",
    main15: "Our key features",
    main16: "Protocols and Platforms",
    main17: "We support popular VPN protocols and major client platforms",
    main18: "We support popular VPN protocols:",
    main19: "and major client platforms:",
    main20: 'Works out of the "box"',
    main21: "Easy to use in new applications as well as existing apps",
    main22: "Focus On Accessibility",
    main23: "Choose from thousands of server endpoints around the world or the quickest for your users",
    main24: "We Take Privacy Seriously",
    main25: "We do not store, log or monitor individual user data or traffic",
    main26: "Simple to Manage",
    main27: "Monitor and manage your apps from a single dashboard in your account",
    main28: "Ready to get started?",
    main29: "FOR FREE",
    main30: "Get a FREE API key",
    features: "Features",
    overrides: "Overrides",
    vpnCredentials: "VPN credentials",
    noVPNCredentials: "No VPN credentials",
    noFeatures: "No features",
    noOverrides: "No overrides",
    help: "Help",
    privacyPolicy: "Privacy policy",
    docs: "Documentation",
    vpn: "VPN",
    vpnEnterprise: "Custom i.e. in Network Deployments",
    vpnFree: "50Mb/day limit",
    vpnPremium: "Unlimited",
    version: "version",
    vpnPremiumBusiness: "More 50 users including Fireshield, 10 VPN Gateways",
    vpnEssentials: "150Mb/day limit",
    vpnEssentialsBusiness: "Up to 20 users including Fireshield, 3 VPN Gateways",
    perMonthly: "per monthly active user",
    fireshieldPremium: "prevent + detect and block",
    fireshieldFree: "detect only",
    fireshieldEssentials: "detect and block",
    unblocker: "Unblocker",
    unblockerFree: "market check",
    unblockerPremium: "measure clicks",
    unblockerEssentials: "measure clicks",
    productUnblocker:
        "It gives the possibility to unblock content from different services such as Netflix (US and UK Netflix), torrents, Apple TV, Amazon TV, HBO and others.",
    fireshield: "Fireshield",
    hydra: "SNI config",
    invites: "Invites",
    buyNow: "Buy now",
    tryFree: "Try for free",
    monthsFree: "Get 3 months for free",
    contactUs: "Contact us",
    contactUsDescription: "Our expert will get in touch to discuss your business needs",
    business: "Business",
    businessVPN: "Business VPN",
    sdWan: "SD-WAN",
    sdWanText:
        "A Software-defined Wide Area Network (SD-WAN) is a virtual WAN architecture that allows enterprises to leverage any combination of transport services – including MPLS, LTE and broadband internet services – to securely connect users to applications.",
    trustsProducts: "Who trusts our products",
    ourAdvantages: "Our advantages",
    keyFeatures: "Key features",
    ruleNumber: "Rule number",
    advantagesMAU: "15M+ MAU (monthly active users)",
    advantagesDescriptionMAU: "on our platform",
    advantagesProjects: "1000+ projects",
    advantagesDescriptionProjects: "successfully implemented",
    advantagesSLA: "99.9% SLA",
    focusOnAccessibility: "Focus on Accessibility",
    descriptionFocusOnAccessibility:
        "Choose from thousands of server endpoints around the world or the quickest for your users",
    advantagesDescriptionSLA: "for all projects",
    productSdWan:
        "A Software-defined Wide Area Network (SD-WAN) is a virtual WAN architecture that allows enterprises to leverage any combination of transport services – including MPLS, LTE and broadband internet services – to securely connect users to applications.",
    productBusinessVPN:
        "It gives the possibility of any kind of company to provide VPN access for its employees using custom-developed apps on iOS, Android, Windows, and macOS platforms",
    productVpn:
        "VPN provides anonymity through hiding the used IP from visited webpages and used applications. User data is encrypted keeping data anonymous from third parties like the ISP. VPNs allow evading of geographical blocked webpages as well as allowing access to locally censored content.",
    productFireshield:
        "Fireshield technology monitors and controls all DNS and IP requests in real time and handles it depending on the configuration",
    needSecurity: "Why do you need security?",

    easyIntegration: "Easy integration",
    contactAdministrator: "You don't have permission to access this website contact administrator",
    somethingWentWrong: "Something went wrong",
    easyIntegrationText: "Integrating with partner’s own threat detection databases to detect threats",
    security: "Security",
    securityText:
        "Defining specific actions by threat type: do nothing, show a warning page (HTTP only), send a notification, turn on VPN, block the traffic",
    safety: "Safety",
    safetyText: "Blocking online trackers, malware, phishing and untrusted sites",
    smartSummarizing: "Easy integration",
    smartSummarizingText: "Daily summary of the threats, websites detected and blocked",
    Privacy: "Privacy",
    privacyText: "Your emploees can connect to your office network or private servers",
    vpnPossibilityTitle: "The fastest VPN",
    vpnPossibilityText:
        "The Flow shows the interaction of the participants in the VPN channel establishment process between the user application and the platform's VPN node.",
    publicAPIPossibilityTitle: "Public API",
    publicAPIPossibilityText:
        "Partner API provides the whole set of methods that can be performed by a partner entity e.g. administrator login/logout, create/modify/delete a subscriber, get list of subscribers, list purchases for subscriber etc.",
    highConfigurablePossibilityTitle: "High configurable",
    highConfigurablePossibilityText:
        "SDK provides VPN infrastructure implemented as Windows Service based on OpenVPN protocol. SDK also contains a sample application of how to work with it.",
    vpnProtocolsPossibilityTitle: "High–level VPN protocols",
    vpnProtocolsPossibilityText:
        "Hydra, IPSEC, OpenVPN’ to ‘There are IPsec, OpenVPN-UDP, OpenVPN-TCP protocols including unique patented Hydra Protocol",
    possibility64Percent: "of companies and apps experience web-based attacks",
    possibility62Percent: "of companies experience phishing and social engineering attacks",
    possibility59Percent: "of companies experience malicious code and botnet attacks",
    possibility8MPercent: "was average cost of a data breach in US",
    possibility7Percent: "average drop in share price after breach",
    footnotePossibility: "“Cost of a Data Breach” , IBM, 2019",
    consumerProducts: "Consumer Products",
    businessProducts: "Business Products",
    signIn: "Sign In",
    securityService: "Security as a service",
    descriptionSecurityService:
        "Build secure apps that can’t be throttled or restricted. We consider freedom on the internet as the human right",
    signUp: "Sign Up",
    nextStep: "Next Step",
    abilityCreateProjects: "Create projects with unique parameters for your applications",
    descriptionAbilityCreateProjects:
        "You can choose what kind of project do you need and which tariff is suitable for you",
    abilityControlProjects: "Control your current projects",
    descriptionAbilityControlProjects: "Easy to check statistic on your projects",
    abilityManage: "Manage users",
    descriptionAbilityManage:
        "Easy to control user access and on-board and off-board users to and from developer portal resources",
    abilityExploreDashboard: "Explore Dashboard with real-time data",
    descriptionAbilityExploreDashboard: "Full of functions dashboard always ready to show you all project’s statistic",
    learnMore: "Learn more on",
    products: "Products",
    consumer: "Consumer",
    projectIncludes: "project includes:",
    pricing: "Pricing",
    ourPartners: "Our Partners",
    haveQuestions: "Still have questions?",
    needConsultation: "Or need a consultation?",
    tryIt: "Try it now",
    refresh: "Refresh",
    dataRefresh: "Data Refresh",
    currentData: "Current Data",
    noData: "No data",
    addClientNetwork: "Add client network",
    editClientNetwork: "Edit client network",
    deleteClientNetwork: "Delete client network",
    currentPlan: "Current plan",
    sorryNoData: "Sorry, no data found",
    allProjects: "All projects",
    addProject: "Add project",
    searchProject: "Search project",
    searchObjects: "Search objects",
    searchToken: "Search token",
    searchKey: "Search key",
    users: "Users",
    id: "id",
    name: "Name",
    grantor: "Grantor",
    accountName: "Name",
    activatedDevices: "Activated devices",
    condition: "Status",
    registrationTime: "Registration time",
    registrationDate: "Registration date",
    month: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"],
    searchUsers: "Search users",
    uid: "User ID",
    unm: "User Name",
    utk: "User Token",
    uex: "User Extref",
    did: "Device ID",
    add: "Add",
    addPermission: "Add permission",
    addLabel: "Add label",
    permissions: "Permissions",
    username: "Username",
    email: "Email",
    fieldPassword: "Password",
    licence: "License",
    authentifications: "Auth methods",
    extref: "Extref",
    cancel: "Cancel",
    cancelUser: "Cancel user",
    saveUser: "Add accessToken",
    provider: "Provider",
    traffic: "Traffic",
    trafficStart: "Start",
    trafficUsed: "Used",
    trafficRemaining: "Remaining",
    trafficLimit: "Limit",
    enabled: "Enabled",
    total_servers: "Total servers",
    available_servers: "Available servers",
    disabled_servers: "Disabled servers",
    unavailable_servers: "Unavailable servers",
    disabled: "Disabled",
    paid: "Paid",
    free: "Free",
    pay: "Pay as you go",
    freePlan: "ALL users has the bandwidth limit 50MB per day",
    payPlanFreeUsers: "Free users has the bandwidth limit 50 Mb per day",
    payPlanPaidUsers: "Premium (paid) users - UNLIMITED",
    priceUnlimitedPlan: "$ 3 per MAU (monthly active users)",
    freeUpgrade: "Upgrade any time",
    upgradeNow: "Upgrade now",
    downgradeNow: "Downgrade now",
    payUpgrade: "Monthly Active Users based payment",
    openFeatures: "Open features",
    hideFeatures: "Hide features",
    showFeatures: "Show features",
    typeFree: "Up to 3 users you are free of payment",
    typeFreeText: "up to 3 users",
    enterprise: "Enterprise",
    premium: "Premium",
    typePremiumText: "up to 100 users",
    typePremium: "With 100 users you will get billing \n" + "per license",
    mostPopular: "Most popular",
    essentials: "Essentials",
    typeEssentials: "Up to 10 users you will get billing \n" + "per license",
    typeEssentialsText: "up to 10 users",
    priceDescription: "means a user who interacted with such service at least once during a particular month",
    unlimited: "Unlimited",
    typeUnlimited: "Contact us and we will choose suitable plan for you",
    limited: "Limited",
    sessions: "Sessions",
    purchases: "Purchases",
    social: "Social",
    allDevices: "All Devices",
    today: "Today",
    thisWeek: "This week",
    thisMonth: "This month",
    yesterday: "Yesterday",
    lastWeek: "Last week",
    lastMonth: "Last month",
    activeSessions: "Active sessions",
    noSessionsRow1: "No sessions have been found for the chosen period",
    noSessionsRow2: "Try to choose other options",
    noDeviceRow: "User has no devices",
    noSocialRow: "User has no social",
    noPurchasesRow: "User has no purchases",
    accessToken: "Access token",
    ok: "Ok",
    confirmLetter: "A confirmation letter was sent to your mailbox",
    checkMailbox: "Check your mailbox",
    emailVerified: "Your email has been verified",
    actionInvalid: "The action code is invalid",
    passwordUpdated: "Password successfully updated",
    successful: "Successful",
    successfulConfirmed: "Your payment details successfully confirmed",
    serverError: "Internal server error",
    jsonMethodSettingsError: "Method settings field must contain a valid JSON structure",
    incorrectLicenseMetadata: "Field must contain a valid JSON structure",
    filedConnect: "Failed to connect to server. Try again later.",
    operationCancelled: "Operation was cancelled",
    descriptionActionInvalid: "This can happen if the code is malformed, expired, or has already been used",
    descriptionEmailVerified: "You can now sign in with your new account",
    descriptionCheckMailbox: "We have sent you an email with a link to reset your password",
    descriptionConfirmLetter: "Please confirm your account in order to start using the application",
    deviceType: "Device type",
    deleteDevice: "Delete device",
    deleteDeviceConfirmation: "Do you really want to delete device?",
    transactionId: "Transaction ID",
    productId: "Product ID",
    type: "Type",
    transactionTime: "Transaction time",
    expirationTime: "Expiration time",
    active: "Active",
    subscription: "Subscription",
    trial: "Trial",
    rawData: "Raw data",
    disablePurchaseConfirmation: "Do you really want to disable purchase?",
    enablePurchaseConfirmation: "Do you really want to activate purchase?",
    activatePurchase: "Activate purchase",
    disablePurchase: "Disable purchase",
    value: "Value",
    editUser: "Edit user",
    editMember: "Edit member",
    editLimit: "Set limit",
    limit: "Limit",
    saveChanges: "Save changes",
    successChanges: "Changes have been successfully applied",
    goToCheckout: "Go to checkout",
    trafficReset: "Traffic reset",
    setTrafficUnlimited: "Set traffic unlimited",
    signInError: "Email and password do not match",
    linkInvalid: "Link you went through is expired, or has already been used. Try to reset password again.",
    mFAError:
        "One-time code verification error. Please make sure the value is the same as received in the SMS or try to request another one",
    resetPasswordError: "Link you went through is expired, or has already been used. Try to reset password again.",
    deleteData: "Delete data",
    userData: "User data",
    disableUser: "Disable user",
    enableUser: "Enable user",
    disableUserDescription: "Do you really want to disable user?",
    enableUserDescription: "Do you really want to enable user?",
    network: "Network",
    settings: "Settings",
    noUsers: "No users found",
    noSessions: "No sessions found",
    noUser: "No user",
    deleteAuthMethod: "Delete auth method",
    deleteAuthMethodConfirmation: "Do you really want to delete auth method?",
    editAuthMethod: "Edit auth method",
    authMethod: "Auth method",
    authSettings: "Method settings",
    addAuthMethod: "New auth method",
    paymentsMethod: "Payment methods",
    paymentMethod: "Payment method",
    newPassword: "New password",
    paymentsSettings: "Method settings",
    addPaymentsMethod: "New payments method",
    editPaymentsMethod: "Edit payments method",
    noPayments: "Project doesn't have payments methods",
    noAuthentifications: "The project does not have any methods yet",
    noMembersYet: "Project does not have any members yet",
    internalServerError: "Internal server error",
    deletePaymentsMethod: "Delete payments method",
    deletePaymentsMethodConfirmation: "Do you really want to delete payments method?",
    role: "Role",
    addUser: "Add user",
    deleteUser: "Delete user",
    deleteRole: "Delete role",
    deleteUserConfirmation: "Do you really want to delete member?",
    deleteInviteUser: "Do you really want to delete invite?",
    deleteRule: "Delete rule",
    parameters: "Parameters",
    grants: "Grants",
    projectName: "Project name",
    objectType: "Object type",
    deleteProject: "Delete project",
    deleteProjectConfirmation: "Do you really want to delete project?",
    publickey: "Project ID",
    hash: "Hash",
    private: "Private",
    privatekey: "Private Key",
    description: "Description",
    projectType: "Project type",
    createProject: "Create project",
    createFirstProject: "Create first project",
    paymentDetails: "Payment details",
    promoCode: "Promo code",
    apply: "Apply",
    SSID: "SSID",
    BSSID: "BSSID",
    descriptionPaymentDetails:
        "We are here for helping you to create projects with unique parameters for your applications. Let’s start! ",
    selectProjectType: "Select a project type",
    descriptionSelectProjectType:
        "First of all, let’s choose your main goal here. What kind of project \n" + "you are going to create?",
    descriptionCreateProject:
        "We are here for helping you to create projects with unique parameters for your applications. Let’s start!",
    paymentDetailsNewCard: "Please enter your payment details carefully",
    paymentDetailsOldCard: "Please choose your payment way",
    projectCreating: "Project creating",
    descriptionLogo:
        "Your logo have to be JPG, SVG or PNG are allowed. \n" +
        "Stand logo need to be at least 24x24 pixels or higher.",
    planChoosing: "Plan choosing",
    chooseThePlan: "Choose the plan",
    planIncludes: "Your plan includes",
    descriptionPlanIncludes: "Your charge will depends on users traffic which they paid for ",
    descriptionChooseThePlan:
        "We are here for helping you to create projects with unique parameters for your applications. Let’s start! ",
    create: "Create",
    accept: "Accept",
    back: "Back",
    trialPeriod: "1 month free trial",
    helperProjectName: "Project name field should be in English.\n" + "It is a mandatory field.",
    helperProjectId:
        "It should be a unique ID for the project that can contain Latin characters and numbers at least 5 characters, another name for Project ID \n" +
        "is a carrier. It is a mandatory field.",
    adminOptional: "Admin email (optional)",
    changeRole: "Change role",
    invited: "Invited",
    waiting: "Waiting",
    general: "General",
    vpnService: "VPN Service",
    fireshieldService: "Fireshield Service",
    member: "Members",
    addMember: "Add member",
    sendInvite: "Send invite",
    deleteMember: "Delete member",
    deleteInvite: "Delete invite",
    deleteLicenseKey: "License key deletion",
    inviteUser: "Invite user",
    inviteMember: "Invite member",
    invite: "Invite",
    reducer: "Reducer",
    defaultValue: "Default value",
    entitlements: "Entitlements",
    noProjects: "No projects",
    noEntitlements: "No entitlements",
    noGrants: "No grants",
    noObjects: "No objects",
    noPermissions: "You don't have read permissions",
    dashboard: "Dashboard",
    signOut: "Logout",
    theme: "Theme",
    locales: "Language",
    viewProfile: "View Profile",
    profile: "Profile",
    updateToS: "Updates to our terms of service",
    mainInfo: "Main information",
    addressInfo: "Address information",
    resetPassword: "Reset password",
    reseted: "Done. Link was sent to your email.",
    url: "URL",
    bandwidthType: "Bandwidth type",
    config: "Config",
    networkTemplate: "ComponentNetwork template",
    passwordOptional: "Password (optional)",
    givenName: "Given name",
    locations: "Locations",
    addLocation: "Add location",
    authorizationType: "Authorization type",
    noProjectSessions: "The project has no active sessions at the time",
    off: "off",
    secondSymbol: "s",
    minuteSymbol: "m",
    hourSymbol: "h",
    daySymbol: "d",
    enterCorrectEmail: "Enter correct e-mail",
    fillInFields: "Fill in the highlighted fields.",
    uploadSize: "Maximum file size is 5Mb",
    uploadFormats: "Please use jpg, jpeg, png or gif",
    sizeIcon: "Stand logo need to be at least 24x24 pixels or higher",
    fullMonth: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    yearSymbol: "",
    fullWeek: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
    from: "From",
    till: "Till",
    server: "Server",
    startTime: "Start time",
    endTime: "End time",
    internalIp: "Internal IP",
    download: "Download",
    upload: "Upload",
    labels: "Labels",
    user: "User",
    userId: "User ID",
    copiedSuccess: "The text has been copied",
    priority: "Priority",
    by_user: "By user",
    by_device: "By device",
    proxy: "Proxy",
    public_vpn: "Public VPN",
    private_vpn: "Private VPN",
    business_vpn: "Business VPN",
    icon: "Icon",
    changeImage: "Change image",
    chooseImage: "Choose image",
    uploadImage: "Upload image",
    roles: {
        OWNER: {
            name: "Owner",
            description: "Project owner",
        },
        ADMIN: {
            name: "Admin",
            description: "Project admin",
        },
        SUPPORT: {
            name: "Support",
            description: "Project support",
        },
    },
    locationsList: {
        no: "Norway",
        de: "Germany",
        hk: "Hong Kong",
        ru: "Russian Federation",
        jp: "Japan",
        dk: "Denmark",
        fr: "France",
        ua: "Ukraine",
        sc: "Seychelles",
        br: "Brazil",
        se: "Sweden",
        sg: "Singapore",
        gb: "United Kingdom",
        id: "Indonesia",
        ie: "Ireland",
        ca: "Canada",
        us: "United States",
        ch: "Switzerland",
        in: "India",
        mx: "Mexico",
        it: "Italy",
        es: "Spain",
        ar: "Argentina",
        au: "Australia",
        "us-south": "",
        cz: "Czech Republic",
        ro: "Romania",
        tr: "Turkey",
        nl: "Netherlands",
    },
    sessions_by_protocol: "Active Sessions by Protocol",
    sessions_by_location: "Active Sessions by Locations",
    devices: "Devices",
    connected_users: "Daily, Weekly, Monthly Connected Users",
    connected_devices: "Daily, Weekly, Monthly Connected Devices",
    inactive_users: "Inactive Users",
    inactive_devices: "Inactive Devices",
    network_traffic: "Network Traffic",
    location_loading_table: "Location loading",
    location_loading_map: "Location loading map",
    defaultBandwidthLimit: "Default Bandwidth limit (Mb)",
    supportIos: "iOS",
    supportAndroid: "Android",
    supportMacos: "MacOS",
    supportWindows: "Windows",
    checkboxSupportTitle: "Client Support of applications",
    defaultLicence: "Default License",
    clientSupport: "Client Support",
    inviteStatus: "Invite status",
    bulkUserDataUpload: "Add multiple users",
    bulkUserAddCsv: "Add file",
    bulkUserError: "Wrong file format",
    bulkUserMalformed: "File malformed",
    bulkUserEmpty: "File empty",
    dashboardSafari: "Safari doesn't support Dashboard",
    noInvoices: "You have no invoices",
    exportData: "Export data",
    licenseKeys: "License keys",
    exportUsersData: "Export users data",
    exportUsersDataText: "Do you want to export users data?",
    exportDevicesData: "Export devices data",
    exportDevicesDataText: "Do you want to export devices data?",
    exportSessionsData: "Export sessions data",
    exportSessionsDataText: "Do you want to export sessions data?",
    vpnBypassList: "VPN Bypass list",
    addBypass: "Add bypass domain",
    domain: "Domain",
    save: "Save",
    searchDomains: "Search domains",
    status: "Status",
    done: "Done",
    error: "Error",
    errorMessage: "Error",
    error1: "Password must be at least 6 characters",
    error2: "User ID must be at least 6 characters",
    error3: "Please enter valid User ID. A-Z, a-z, 0-9, _.",
    error4: "A valid email is required",
    error5: "User already exist",
    error6: "File empty",
    downloadExample: "Download example",
    reportCreated: "Report created",
    created: "Created",
    invoiceNumber: "Invoice number",
    billingPeriod: "Billing period",
    amount: "Amount",
    payStatus: "Pay status",
    pdf: "pdf",
    exportFrom: "From",
    exportTill: "Till",
    exportStatus: "Status",
    exportUsers: "Export Users",
    exportDevices: "Export Devices",
    carrier: "Carrier",
    exportSessions: "Export Sessions",
    BY_CARRIER: "Sessions",
    BY_ALL_USERS: "Users",
    BY_USER: "User",
    BY_USER_ID: "User id",
    BY_ALL_DEVICES: "Devices",
    NEW: "In progress",
    EXPORTING: "In progress",
    COMPLETED: "Done",
    EXPORTING_FAILED: "Failed",
    QUERYING_FAILED: "Failed",
    domainNotValid: "Domain is not valid",
    valueNotValid: "Value is not valid",
    usersAdd: "Add",
    usersAddOne: "Add users",
    usersAddMultiple: "Add multiple users",
    exportAdd: "Add",
    bulkUserLogStatus: "Status",
    domainsSaveQuestionTitle: "Save",
    domainsSaveQuestionText: "Do you want to save domains list?",
    domainsSaveQuestionCancel: "Cancel",
    domainsSaveQuestionSubmit: "Save",
    "The password is invalid or the user does not have a password.": "User already exist in the platform",
    exportDataSubmit: "Export data",
    updateSelectedUsers: "Update",
    updateSelectedUsersBtn: "Update",
    updateSelectedUsersTitle: "Update",
    updateSelectedUsersCbTitle: "Update",
    selectField: "Select a field",
    setNewValue: "Please enter new value",
    massUpdate: "Mass update",
    devicesNumber: "Device number",
    devicesAny: "Any",
    devicesMore: "More than",
    devicesLess: "Less than",
    devicesExact: "Exact",
    usersAll: "Show all",
    usersActive: "Show active",
    usersDisabled: "Show disabled",
    usersInvited: "Show invited",
    usersActiveAndInvited: "Show active and invited",
    find: "Find",
    reset: "Reset",
    applications: "Applications",
    downloadUserUpdateLog: "Download",
    dashboardGeneral: "General",
    dashboardLocation: "Location loading",
    log: "Log",
    load: "Load",
    invoices: "Invoices",
    eventDate: "Date",
    eventAuthor: "Author",
    eventType: "Type",
    eventChanges: "Changes",
    loadMore: "Load more",
    filters: "Filters",
    events: {
        CREATE_FIREBASE_ADMIN: "New Member",
        UPDATE_CARRIER_FIREBASE_ADMIN_ROLE: "Change Role",
        DELETE_CARRIER_FIREBASE_ADMIN: "Delete Member",
        CREATE_CARRIER_TEST_PURCHASE_SETTINGS: "New Payments method",
        UPDATE_CARRIER_TEST_PURCHASE_SETTINGS: "Change Payments method",
        DELETE_CARRIER_PURCHASE_SETTINGS: "Delete Payments method",
        CREATE_AUTH_METHOD: "New Auth method",
        DELETE_CARRIER_AUTH_METHOD: "Delete Auth method",
        UPDATE_CARRIER_AUTH_METHOD: "Change Auth method",
        UPDATE_CARRIER: "Change project parameters",
        UPDATE_CARRIER_ICON: "Update Icon",
        ADD_FILE: "File",
        CREATE_SUBSCRIBER: "New User",
        UPDATE_SUBSCRIBER: "Change User data",
        MASS_USER_UPDATE: "Change Users data",
        DELETE_USER_DATA: "Delete User data",
        SET_TRAFFIC_LIMIT: "Set Traffic limit",
        DELETE_TRAFFIC_LIMIT: "Delete Traffic limit",
        DELETE_DEVICE: "Delete device",
        CREATE_CARRIER_COUNTRY: "Add location",
        DELETE_CARRIER_COUNTRY: "Delete location",
        DISABLE_USER: "Disable User",
        PARTNER_LOGIN: "Log In",
        PARTNER_LOGOUT: "Log Out",
        GENERATE_REPORT: "Create report",
        CREATE_FIREBASE_ADMIN_CHANGES1: "User: ",
        CREATE_FIREBASE_ADMIN_CHANGES2: ", Role: ",
        UPDATE_CARRIER_ICON_CHANGES: "Icon",
        ADD_FILE_CHANGES: "File",
        ADD_FILE_BPL: "Bypass List",
        ADD_FILE_CNL: "Client ComponentNetwork List",
        USER: "User",
        DEVICE: "Device",
        CREATE_CARRIER: "Create project",
    },
    custom: "Custom",
    customer: "Customer",
    period: "Period",
    anyDate: "Any date",
    selectPlaceholderDefault: "Select",
    selectPlaceholderType: "Select type",
    interval: "Interval",
    dashboardInterval: "Interval",
    any: "Any",
    clientNetworks: "Client Networks",
    enable: "Enable",
    disable: "Disable",
    yes: "Yes",
    no: "No",
    doesntMatter: "Doesn't matter",
    ssidLabel: "SSID, comma separator",
    bssidLabel: "BSSID, comma separator",
    authorized: "Authorized",
    action: "Action",
    addClientNetworkTitle: "Add Client ComponentNetwork record",
    editClientNetworkTitle: "Edit Client ComponentNetwork record",
    deleteClientNetworkTitle: "Delete Client ComponentNetwork record",
    deleteClientNetworkText: "Do you want to delete Client ComponentNetwork record?",
    delete: "Delete",
    fullTextSearchMsg: "Full text search require at least 3 characters.",
    authors: "Authors",

    DONE: "Done",
    SKIPPED: "Skipped",
    NO_VALUES: "No values",
    WRONG_VALUE: "Wrong value",
    noChanges: "No changes was made",
    optimalLocation: "Optimal location",
    noRules: "No rules are currently set",
    noInvites: "No invites are currently set",
    noEvents: "No events",
    noCountries: "No countries",
    noLocations: "No locations",
    noGroups: "No groups",
    noDomains: "No domains",
    noMembers: "No members",
    noLicenseKeys: "No license keys",
    noPools: "No pools",
    pools: "Pools",
    auth: "Auth",
    access: "Access",
    rolesPage: "Roles",
    editPool: "Edit pool",
    addGroup: "Add new group",
    editGroup: "Edit the group",
    user_country: "User country",
    user_region: "User region",
    server_country: "Server country",
    server_city: "Server city",
    device_type: "Device type",
    user_state: "User state",
    app_version: "App version",
    sdk_version: "SDK version",
    user_city: "User city",
    domains: "Domains",
    itAssets: "IT assets",
    noItAssets: "No IT assets",
    noRoles: "No roles",
    createUser: "Create user",
    createRole: "Create role",
    editRole: "Edit role",
    allow_paths: "Allow paths",
    allow: "Allow",
};
